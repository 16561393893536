/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* 
This media query will apply styles when the minimum
width of the viewport is 992 pixels or more. 
It is commonly used for targeting larger screens, such as tablets and desktops.
*/
@media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    margin: 20px 0 0 20px;
    padding: 13px 20px;
    height: calc(100vh - 20px);
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
    margin-left: 270px;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
}

/* 
This media query will apply styles when the minimum
width of the viewport is 768 pixels or more. 
It is commonly used for targeting screens larger than mobile but smaller than tablets.
*/
@media (min-width: 768px) {
  .ant-layout {
    margin: 0 20px;
  }

  .layout-dashboard .ant-layout-header {
    margin: 10px 20px;
  }
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}

/* 
This media query will apply styles when the minimum
width of the viewport is 992 pixels or more. 
It is commonly used for targeting larger screens, such as tablets and desktops.
*/
@media (min-width: 992px) {
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
}

/*  
This media query will apply styles when the maximum 
width of the viewport is 768 pixels or less. 
It is commonly used for targeting small screens, such as mobile devices.
*/
@media (max-width: 768px) {
  .ant-layout {
    margin: 0 20px;
  }

  .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;
  }
}
