.ant-form-item .ant-form-item-label {
  font-weight: bold;
}

.arabic-field,
.arabic-field input,
.arabic-field textarea {
  text-align: right;
  direction: rtl;
  font-family: "Noto Kufi Arabic", sans-serif;
}

.english-field,
.english-field input,
.english-field textarea {
  text-align: left;
  direction: ltr;
  font-family: "Figtree", sans-serif;
}

input[type="file"] {
  font-family: "Figtree", sans-serif;
}

.ant-input-affix-wrapper {
  padding: 0 11px;
}

.button-with-padding {
  padding: 25px 35px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* search bar styling */
.searchbar-row .ant-input-group .ant-input-group-addon,
.searchbar-row .ant-input {
  border-radius: 50px;
  border: 1px solid #d9d9d985;
}

.searchbar-row .ant-input-group .ant-input-group-addon:first-child {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

/* filter button styling */
.filter-btn {
  min-width: 40px !important;

  svg {
    margin: 0 !important;
  }
}

/* remove sort icons form antd table */
.ant-table-column-sorter {
  display: none;
}

/* remove background color when hover over menu item */
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: #fff;
}

/* font regardless of current language */
.tshirt-size-select .ant-select-selection-item,
.ant-empty-description,
.ant-pagination .ant-pagination-item,
.ant-pagination-options .ant-select-dropdown .ant-select-item-option-content,
.ant-picker-panel-layout,
.ant-table-filter-dropdown-btns .ant-btn,
.copyright,
.cart-badge {
  font-family: "Figtree", sans-serif;
}
